<template>
  <a-tabs :activeKey="activeKey" @change="onChange">
    <a-tab-pane v-for="tab in tabs" :key="tab.value" :tab="tab.name"></a-tab-pane>
  </a-tabs>
  <router-view />
</template>

<script>
export default {
  data() {
    return {
      tabs: [
        { name: 'General', value: 'settings' },
        { name: 'About', value: 'settings-about' },
        { name: 'Subscription', value: 'settings-subscription' },
        { name: 'Invoices', value: 'settings-invoices' },
        { name: 'API Access Key', value: 'settings-keys' },
      ],
    }
  },

  computed: {
    activeKey() {
      var splitRouteName = this.$route.name.split('-')

      if (splitRouteName[3]) {
        return `${ splitRouteName[2] }-${ splitRouteName[3] }`
      }

      return splitRouteName[2]
    },
  },

  methods: {
    onChange(key) {
      this.$router.push({ name: `publications-id-${ key }` })
    },
  },
}
</script>
